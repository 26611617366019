<template>
  <div class="search-page" v-if="this.$store.state.search.searchResult.done">
    <section class="search-filters">
      <div class="container">
        <h2>نتائج البحث:</h2>
        <form class="row" @submit="getSearchResult(false)">
          <div class="col-md-8  mb-md-0 mb-4">
            <input type="text" v-model="keyword" required/>
          </div>
          <div class="col-md-4">
            <div class="mySelect">
              <font-awesome-icon icon="chevron-down"/>
              <select v-model="selectedCategory" @change="updateCategory">
                <option value="">الكل</option>
                <option :value="option.value" v-for="option in category">{{ option.name }}</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </section>
    <section class="searchResult">
      <div class="container">
        <div class="result-group"
             v-if="(selectedCategory==='' || selectedCategory==='article') && this.$store.state.search.searchResult.articles.length > 0">
          <h5>مقالات</h5>
          <hr>
          <div class="items-container row justify-content-center">
            <!--    Article Item    -->
            <div class="col-lg-4 col-md-6 col-12" v-bind:key="item.id"
                 v-for="item in this.$store.state.search.searchResult.articles">
              <router-link class="article-item" :to="{name:'singleArticle',params:{id:item.id}}">
                <img
                    :src="item.image_full_path"
                    alt="Article Image"
                />
                <div class="date">
                  <p>{{ item.created_at }}</p>
                </div>
                <div class="title">
                  <p>
                    {{ item.title }}
                  </p>
                </div>
                <div class="description">
                  <p>
                    {{ item.short_description }}
                  </p>
                </div>
                <div class="author">
                  <p>{{ item.blogAuthor.name }}</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="result-group"
             v-if="(selectedCategory==='' || selectedCategory==='podcast') && this.$store.state.search.searchResult.podcast.length > 0">
          <h5>بودكاست</h5>
          <hr>
          <div class="items-container row justify-content-center">
            <div
                class="col-lg-4 col-md-6 col-12 " v-bind:key="item.id"
                v-for="item in this.$store.state.search.searchResult.podcast"
            >
              <div class="episode-item">
                <router-link
                    :to="{name: 'Episode', params:{id:item.id}}"
                >
                  <img :src="item.image_full_path" alt="Episode Image"/>
                  <h5>
                    {{ item.title }}
                  </h5>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="result-group"
             v-if="(selectedCategory==='' || selectedCategory==='video') && this.$store.state.search.searchResult.videos.length > 0">
          <h5>مرئيات</h5>
          <hr>
          <div class="items-container row justify-content-center">
            <div class="col-lg-4 col-md-6" v-bind:key="item.id"
                 v-for="item in this.$store.state.search.searchResult.videos">
              <router-link :to="{name:'singleVideo',params:{id:item.id}}" class="episode-item">
                <img
                    class="img-fluid"
                    :src="$store.state.generalStore.urlPrefixForImages+item.featured_image"
                    alt="Episode Image"
                />
                <h5>{{ item.title }}</h5>
                <p>
                  {{ item.short_description }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "search",
  data() {
    return {
      keyword: this.$route.params.keyword,
      selectedCategory: '',
      category: [
        {name: 'مقالات', value: 'article'},
        {name: 'بودكاست', value: 'podcast'},
        {name: 'مرئيات', value: 'video'},
      ],
    }
  },
  watch: {
    '$route.params.keyword': {
      handler: function (search) {
        this.keyword = this.$route.params.keyword;
        this.getSearchResult(true);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    updateCategory(event) {
      this.selectedCategory = event.target.value;
    },
    getSearchResult(isNew) {
      const isJustPodcast = this.$route.params.isPodcast ?? false;
      this.$store.dispatch("loading/show");
      if (!isNew) {
        event.preventDefault();
        this.$router.push({name: 'Search', params: {keyword: this.keyword, isPodcast: false}});
      } else {
        this.keyword = this.$route.params.keyword;
      }
      this.$store.dispatch("search/getAboutUsPageInfo", {q: this.keyword, is_podcast: isJustPodcast})
          .then(() => {
            this.$store.dispatch("loading/hide");
          })
          .catch(err => {
            console.log(err);
          });
    },
  },
  mounted() {
    this.keyword = this.$route.params.keyword;
  },
  created() {
    // this.keyword = this.$route.params.keyword;
    // this.getSearchResult(true);
  }
}
</script>

<style lang="scss" scoped>
.search-page {
  padding: 4em 0;
  background-color: #fbfcfc;

  .search-filters {
    h2 {
      font-weight: 700;
      margin-bottom: 1em;
    }

    form {
      input {
        display: block;
        width: 100%;
        border: 1px solid rgba(#000000, 0.4);
        padding: 0.25em 1em;
        border-radius: 5px;
        background-color: #fbfcfc;
      }

      .mySelect {
        position: relative;

        svg {
          pointer-events: none;
          position: absolute;
          top: 50%;
          left: 1em;
          transform: translateY(-50%);
          color: rgba(#000000, 0.75);
        }

        select {
          appearance: none;
          display: block;
          width: 100%;
          border: 1px solid rgba(#000000, 0.4);
          padding: 0.25em 1em;
          border-radius: 5px;
          background-color: #fbfcfc;
        }
      }

    }
  }

  .searchResult {
    margin-top: 3em;

    h5 {
      font-size: 2rem;
      font-weight: 700;
    }

    .items-container {
      .article-item {
        display: block;
        margin-bottom: 3em;

        img {
          border-radius: 8px;
          width: 100%;
          object-fit: cover;
          margin-bottom: 0.35em;
        }

        .date {
          p {
            font-size: 0.85rem;
            text-align: end;
            font-weight: 700;
            color: $myBlue;
            margin-bottom: 0;
          }
        }

        .title {
          p {
            font-weight: 700;
            color: $myViolet;
            font-size: 1.4rem;
          }
        }

        .description {
          p {
            color: $myBlue;
            font-size: 1.05rem;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .author {
          p {
            color: $myBlue;
            font-weight: 700;
            font-size: 1.1rem;
          }
        }
      }

      .episode-item {
        padding: 1.5em 1em;
        border-radius: 8px;
        //box-shadow: 0 0 12px rgba(#000000, 0.1);
        overflow: hidden;
        //background-color: #ffffff;
        transition: 0.3s $bounceAnimation;

        &:hover {
          transform: scale(1.05);
        }

        a {
          display: block;
        }

        img {
          width: 100%;
          height: 20em;
          object-fit: cover;
          object-position: center;
          border-radius: 8px;
        }

        h5 {
          margin-top: 1em;
          color: $myViolet;
          font-size: 1.35rem;
          font-weight: 700;
        }

        .episode-info {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 1em;

          .info-item {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            svg {
              margin-inline-end: 0.5em;
              color: $myYellow;
            }

            p {
              margin-bottom: 0;
              font-weight: 700;
              color: $myBlue;
            }
          }
        }
      }

      .episode-item {
        display: block;
        margin-bottom: 1.8em;

        img {
          border-radius: 8px;
          margin-bottom: 0.7em;
        }

        h5 {
          color: $myViolet;
          font-weight: 700;
        }

        p {
          color: #424242;
          font-size: 0.95rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>